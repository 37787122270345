<template>
  <div class="flex min-h-[100vh] flex-col justify-between">
    <LayoutHeader />
    <LayoutContainer>
      <div class="grid items-center justify-stretch py-8">
        <slot />
      </div>
    </LayoutContainer>
    <div class="flex items-end">
      <LayoutFooter minimal />
    </div>
  </div>
</template>
